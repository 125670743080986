import { Workbox } from 'workbox-window';

// this file is only included in production builds
// register service workere in this file
if ('serviceWorker' in navigator) {
    // do not use on vue or react, use appready event!
    window.addEventListener('load', () => {

        const workbox = new Workbox('/sw.js')
        workbox.register();
        workbox.addEventListener('waiting', (event) => {
            console.log('🔰 update available');
            if (typeof gtag === 'function') {
                gtag('event', 'update', {
                    'value': 'loaded'
                })
            }

            workbox.addEventListener('controlling', () => {
                console.log('reload 🔁');
                if (typeof gtag === 'function') {
                    gtag('event', 'update', {
                        'value': 'reload'
                    })
                }
                window.location.reload();
            });

            workbox.messageSW({ type: "SKIP_WAITING" })
        })
    })
}